import '@rails/ujs'

// import Turbolinks from "turbolinks"
// Turbolinks.start()

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

// Import all channels.
const channels = import.meta.globEager('./**/*_channel.js')




import '@pwr/brandon-text'
import { utils } from './utils';

import 'flatpickr/dist/flatpickr.min.css'

window.Pwr = { utils: utils }
